var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"申请修改负责人"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 10 },"wrapper-col":{ span: 10 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"原负责人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['old_name', {
            normalize: this.$lodash.trim
          }]),expression:"['old_name', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"原负责人联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['old_phone', {
            normalize: this.$lodash.trim
          }]),expression:"['old_phone', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"新负责人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_id',{
            rules: [{ required: true, message: '请选择新负责人' }]
          }]),expression:"['user_id',{\n            rules: [{ required: true, message: '请选择新负责人' }]\n          }]"}],attrs:{"allow-clear":""},on:{"focus":_vm.fetchUserOptions,"load":_vm.loadingUserOptions,"change":_vm.handlePhoneNumberChange}},_vm._l((_vm.userOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.emp_name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"新负责人联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            normalize: this.$lodash.trim
          }]),expression:"['phone_number', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"修改原因"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['reason', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入修改原因' },
              { max: 50, message: '最多50个字符' } ]
          }]),expression:"['reason', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入修改原因' },\n              { max: 50, message: '最多50个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"审核人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['personId',{
            initialValue: _vm.personDefaultId,
            rules: [{ required: true, message: '请选择审核人' }]
          }]),expression:"['personId',{\n            initialValue: personDefaultId,\n            rules: [{ required: true, message: '请选择审核人' }]\n          }]"}],attrs:{"allow-clear":""},on:{"focus":_vm.fetchTenantAdministrativeOptions}},_vm._l((_vm.checkUserOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.name)+" ")])}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }