<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="申请修改负责人"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 10 }"
        @submit="handleSubmit"
      >
        <a-form-item label="原负责人">
          <a-input
            :disabled="true"
            v-decorator="['old_name', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="原负责人联系电话">
          <a-input
            :disabled="true"
            v-decorator="['old_phone', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="新负责人">
          <a-select
            v-decorator="['user_id',{
              rules: [{ required: true, message: '请选择新负责人' }]
            }]"
            @focus="fetchUserOptions"
            @load="loadingUserOptions"
            @change="handlePhoneNumberChange"
            allow-clear
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="新负责人联系电话">
          <a-input
            :disabled="true"
            v-decorator="['phone_number', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="修改原因">
          <a-input
            v-decorator="['reason', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入修改原因' },
                { max: 50, message: '最多50个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="审核人">
          <a-select
            v-decorator="['personId',{
              initialValue: personDefaultId,
              rules: [{ required: true, message: '请选择审核人' }]
            }]"
            @focus="fetchTenantAdministrativeOptions"
            allow-clear
          >
            <a-select-option
              v-for="user in checkUserOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findTenantAdministrativeOptions, findOrderAuthorityOptions, findUser } from '@/api/user'
import { modifyHeadUser } from '@/api/order'

export default {
  name: 'ModifyHeadUserApply',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'modify_head_user_apply' }),
      submitting: false,
      userOptions: [],
      loadingUserOptions: false,
      checkUserOptions: [],
      personDefaultId: '',
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    handlePhoneNumberChange(id) {
      this.form.setFieldsValue({ phone_number: undefined })
      if (!id) {
        return
      }
      findUser(id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ phone_number: res.data.phone_number })
        }
      })
    },
    fetchFormData() {
      this.$nextTick(() => {
        this.form.setFieldsValue({ old_name: this.name, old_phone: this.phone })
      })
    },
    fetchUserOptions() {
      this.loadingUserOptions = true
      findOrderAuthorityOptions({ order_id: this.id }).then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data
        }
        this.loadingUserOptions = false
      })
    },
    fetchTenantAdministrativeOptions() {
      findTenantAdministrativeOptions().then((res) => {
        if (res.code === 0) {
          this.checkUserOptions = res.data
          if (this.checkUserOptions.length > 0) {
            this.personDefaultId = this.checkUserOptions[0].id
          }
        }
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          modifyHeadUser(Object.assign({ order_id: this.id }, values)).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
